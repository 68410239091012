/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

window.hashName = window.location.hash;
window.location.hash = '';

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                if (typeof instagram !== 'undefined') {
                    var feed = new Instafeed({
                        get: 'user',
                        userId: instagram.user_id,
                        accessToken: instagram.access_token,
                        template: instagram.template,
                        sortBy: 'most-recent',
                        limit: 4,
                        resolution: 'standard_resolution'
                    });
                    feed.run();
                }
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

    $(document).ready(function () {

        $('.wc-comment-left').on('click', function (e) {
            e.preventDefault();
        });

        // main slider
        $('.main-banner').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            prevArrow: "<button type='button' class='slick-prev'><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
            nextArrow: "<button type='button' class='slick-next'><i class='fa fa-angle-right' aria-hidden='true'></i></button>"
        });
        // offer slider
        $('.slider-offer').slick({
            dots: true,
            infinite: true,
            autoplay: true,
            speed: 1000,
            fade: true,
            cssEase: 'linear',
            arrows: false,
            customPaging: function (slider, i) {
                var thumb = $(slider.$slides[i]).data('thumb'),
                    previewTitle = $(slider.$slides[i]).data('preview-title');
                return '<button>' +
                    '<img src="' + thumb + '">' +
                    '<span class="frame-text helper"><span class="text-el">' + previewTitle + '</span></span>' +
                    '</button>';
            },
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        autoplay: false,
                        speed: 500
                    }
                }
            ]
        });
        // team slider
        $('.slider-team').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            dots: false,
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 479,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
        // references  slider
        $('.slider-reference').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 5000,
            dots: false,
            asNavFor: '.naw-slider-reference',
            accessibility: false
        });

        $('.naw-slider-reference').slick({
            slidesToShow: 5,
            slidesToScroll: 3,
            asNavFor: '.slider-reference',
            dots: true,
            focusOnSelect: true,
            accessibility: false,
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 479,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });

        // fancybox
        $('.fancybox').fancybox({
            openEffect: 'none',
            closeEffect: 'none'
        });

        $('body').on('click', 'a.fancybox', function () {
            $('a.fancybox').each(function () {
                if (!!$(this).data('video')) {
                    $(this).addClass('fancybox.iframe').attr('href', $(this).data('video'));
                }
            });
        });
        // end fancybox

        var homeUrl = $('.logo').data('home-url'),
            isHomePage = $('body').hasClass('home'),
            hHeader = $('.site-header').outerHeight();

        if (!!window.hashName && isHomePage) {
            $(window).load(function () {
                $('html, body').animate({scrollTop: $(window.hashName).offset().top - hHeader}, 1000);
                window.location.hash = window.hashName;
            });
        }

        var existsN = function (nabir) {
            return nabir.length > 0 && nabir instanceof jQuery;
        };

        if (!isHomePage) {
            if(existsN($('.js-link-banner'))){
                console.log(true);
                var bannerHref = $('.js-link-banner').attr('href');
                $('.js-link-banner').attr('href', homeUrl + bannerHref);
            }

            $('.menu-top-menu-container .menu > li').each(function () {
                var $this = $(this),
                    hrefLink = $this.find('a').attr('href');
                if (hrefLink.indexOf('#', 0) === 0) {
                    $this.find('a').attr('href', homeUrl + hrefLink);
                }
            })
        }

        $('.js-link-banner, .menu-top-menu-container .menu a').on('click', function (event) {
            var id = $(this).attr('href'),
                top = $(id).offset().top;
            if (isHomePage) {
                event.preventDefault();
                $('body,html').animate({scrollTop: top - hHeader}, 1500);
                window.location.hash = id;
            }
        });

        $('.js-button-down').on('click', function (event) {
            event.preventDefault();
            var id = $(this).attr('href'),
                top = $(id).offset().top,
                hHeader = $('.site-header').outerHeight();
            $('body,html').animate({scrollTop: top - hHeader - $('.frame-scroll-block').height()}, 1500);
        });

        $('p:empty').remove();

        // menu
        $('.js-mob-menu').on('click', function () {
            $(this).toggleClass('active');
            $('.js-all-header-menu').slideToggle('fast');
        });

        // end menu

        var slickCurrentTitleHeight = $('.slick-current').find('.offer-content').height();
        $('.slider-offer .slick-track').height(slickCurrentTitleHeight);

        $(document).on('click', '.slider-offer .slick-dots li', function () {
            slickCurrentTitleHeight = $('.slick-current').find('.offer-content').height();
            $('.slider-offer .slick-track').height(slickCurrentTitleHeight);
        });
    });

    // Load Events

})(jQuery); // Fully reference jQuery after this point.
